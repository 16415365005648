<template>
  <itemCard :type="cardType" :data="computedData" :action="action" :actionText="actionText" />
</template>

<script>
import listItem from "@/components/liffList/listItem.js";
import orderCardItemMixins from "@/mixins/orderCardItem.js"
export default {
  mixins: [listItem, orderCardItemMixins],
  props: {
    listKey: {
      type: String
    }
  },
  computed: {
    computedData() {
      return [
        {
          text: "",
          value: this.data.provider_name,
          valueCol: 3
        },
        {
          text: "",
          value: this.$helper.orderTimeFormat(this.data.created_at),
          class: this.statusColorClass,
          valueCol: 1
        },
        {
          text: "",
          value: `NT$${Number(this.data.total_amounts)}` + (this.data.items ? `（${this.data.items.reduce((a, b) => a + b.count, 0)}項商品：${this.data.items.map(v => v.product + '*' + v.count).join(', ')}）` : ''),
        },
        {
          text: "訂單編號",
          value: this.data.order_no,
        },
      ];
    },
    authType() {
      const config = this.$store.getters[`list/${this.listKey}/config`]
      if (typeof config.authType == 'undefined') {
        return ''
      } else {
        return config.authType
      }
       
    },
    auth() {
      let res = true
      if (typeof this.authType !== 'undefined' && ['read'].includes(this.authType)) {
        res = false
      }
      return res
    },
  },
  methods: {
    action() {
      let target = {
        name: "store-order-detail",
        params: {
          orderId: this.data.order_no,
          providerId: this.$route.params.providerId,
        },
      };
      if (!this.auth) {
        target = {
          query: {
            authType: 'read'
          },
          ...target
        }
      }

      this.$router.push(target);
    },
  },
};
</script>